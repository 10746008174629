// React imports
import React from 'react';

// UI – style imports
import clsx from 'clsx';
import { WithStyles, withStyles, useMediaQuery } from '@material-ui/core';
import Box from '@material-ui/core/Box';

import logoBuro from 'images/logo-buro.svg';

// local imports
import { styles } from './Buro.style';
import positive from 'images/svg/positive.svg';
import negative from 'images/svg/negative.svg';
import ten from 'images/svg/ten.svg';
import nine from 'images/svg/nine.svg';
import eight from 'images/svg/eight_four_four.svg';
import line from 'images/svg/line.svg';

function Buro(props: WithStyles<typeof styles>) {
    const { classes } = props;

    const blueBreak = useMediaQuery('(min-width:950px)');
    const mobileDots = useMediaQuery('(min-width:900px)');
    const smallerDots = useMediaQuery('(min-width:510px)');
    const reallySmall = useMediaQuery('(min-width:340px)');

    return (
        <Box className={classes.root}>
            <p className={classes.title}>BURÓ</p>

            <p id="first">de entidades financieras</p>
            <br></br>
            <p id="subtitle">¿Qué es el Buró de Entidades Financieras?</p>

            <p id="second">
                Es una herramienta de consulta y difusión con la que podrás conocer los productos que ofrecen las
                entidades financieras, sus comisiones y tasas, las reclamaciones de los usuarios, las prácticas no sanas
                en que incurren, las sanciones administrativas que les han impuesto, las cláusulas abusivas de sus
                contratos y otra información que resulte relevante para informarte sobre su desempeño.
            </p>
            <p id="second">
                Con el Buró de Entidades Financieras, se logrará saber quién es quién en bancos, seguros, sociedades
                financieras de objeto múltiple, cajas de ahorro, afores, entre otras entidades.
            </p>
            <p id="second">
                Con ello, podrás comparar y evaluar a las entidades financieras, sus productos y servicios y tendrás
                mayores elementos para elegir lo que más te convenga.
            </p>
            <p id="second">
                Esta información te será útil para elegir un producto financiero y también para conocer y usar mejor los
                que ya tienes.
            </p>
            <p id="second">
                Este Buró de Entidades Financieras, es una herramienta que puede contribuir al crecimiento económico del
                país, al promover la competencia entre las instituciones financieras; que impulsará la transparencia al
                revelar información a los usuarios sobre el desempeño de éstas y los productos que ofrecen y que va a
                facilitar un manejo responsable de los productos y servicios financieros al conocer a detalle sus
                características.
            </p>
            <p id="second">
                Lo anterior, podrá derivar en un mayor bienestar social, porque al conjuntar en un solo espacio tan
                diversa información del sistema financiero, el usuario tendrá más elementos para optimizar su
                presupuesto, para mejorar sus finanzas personales, para utilizar correctamente los créditos que
                fortalecerán su economía y obtener los seguros que la protejan, entre otros aspectos
            </p>
            <p id="second">
                Buró de entidades: Cuenca Tecnología Financiera, S.A. de C.V., Institución de Fondos de Pago
                Electrónico, está registrada en el Buró de Entidades Financieras dando cumplimiento a lo establecido por
                la Disposición Séptima de las vigentes Disposiciones de carácter general para la organización y
                funcionamiento del buró de entidades financieras emitidas por la Comisión Nacional para la Protección y
                Defensa de los Usuarios de Servicios Financieros. Dicho registro puedes consultarlo en Buró:
                <br></br>
                <a className={classes.linkInsideP} href="https://www.buro.gob.mx/" target="_blank">
                    https://www.buro.gob.mx
                </a>
            </p>
            <br></br>
            <br></br>
            <Box>
                <p id="subtitle">Periodo: Enero — Marzo 2023</p>
                <p className={classes.header}>INSTITUCIÓN</p>
                <br></br>
                <p className={classes.name}>
                    Cuenca Tecnología, S.A. de C.V., Institución de Fondos de Pago Electrónico
                </p>
                <br></br>
                <Box className={classes.name}>
                    <p id="text">Cumplimiento a los Registros de Condusef</p>
                    <Box className={classes.name} marginLeft={'25px'}>
                        <p id="institution">SIPRES</p>
                        <p id="description">
                            Actualizó / Registró Validó Información
                            {reallySmall ? '.....' : ''}
                            {smallerDots ? '.....................' : '...........'}
                            {mobileDots ? '.............................' : ''}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <img src={positive} />
                        </p>
                    </Box>
                    <br></br>
                    <Box className={classes.name} marginLeft={'25px'}>
                        <p id="institution">IFIT</p>
                        <p id="description">
                            Actualizó / Registró Validó Información
                            {reallySmall ? '.....' : ''}
                            {smallerDots ? '.....................' : '...........'}
                            {mobileDots ? '.............................' : ''}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <img src={positive} />
                        </p>
                    </Box>
                    <br></br>
                    <Box className={classes.name} marginLeft={'25px'}>
                        <p id="institution">
                            Calificación 0-10
                            {reallySmall ? '........' : ''}
                            {smallerDots ? '......................................' : '...........'}
                            {mobileDots ? '.......................' : ''}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <img src={ten} />
                        </p>
                    </Box>
                    <br></br>
                    <br></br>
                    <Box className={classes.name}>
                        <p id="text">
                            Programas de educación financiera
                            {reallySmall ? '........' : ''}
                            {smallerDots ? '...............' : '...........'}
                            {mobileDots ? '.......................' : ''}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <img src={negative} />
                        </p>
                    </Box>

                    <Box className={classes.name}>
                        <p id="text">
                            Totales del sector
                            {reallySmall ? '........' : ''}
                            {smallerDots ? '..............................................' : '...........'}
                            {mobileDots ? '.......................' : ''}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <img src={eight} />
                        </p>
                    </Box>
                </Box>
            </Box>
            <Box marginTop={15} marginBottom={10}>
                <img src={line} />
            </Box>
            <Box>
                <p id="subtitle">Periodo: Enero — Diciembre 2022</p>
                <p className={classes.header}>INSTITUCIÓN</p>
                <br></br>
                <p className={classes.name}>
                    Cuenca Tecnología, S.A. de C.V., Institución de Fondos de Pago Electrónico
                </p>
                <br></br>
                <Box className={classes.name}>
                    <p id="text">Cumplimiento a los Registros de Condusef</p>
                    <Box className={classes.name} marginLeft={'25px'}>
                        <p id="institution">SIPRES</p>
                        <p id="description">
                            Actualizó / Registró Validó Información
                            {reallySmall ? '.....' : ''}
                            {smallerDots ? '.....................' : '...........'}
                            {mobileDots ? '.............................' : ''}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <img src={positive} />
                        </p>
                    </Box>
                    <br></br>
                    <Box className={classes.name} marginLeft={'25px'}>
                        <p id="institution">IFIT</p>
                        <p id="description">
                            Actualizó / Registró Validó Información
                            {reallySmall ? '.....' : ''}
                            {smallerDots ? '.....................' : '...........'}
                            {mobileDots ? '.............................' : ''}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <img src={positive} />
                        </p>
                    </Box>
                    <br></br>
                    <Box className={classes.name} marginLeft={'25px'}>
                        <p id="institution">
                            Calificación 0-10
                            {reallySmall ? '........' : ''}
                            {smallerDots ? '......................................' : '...........'}
                            {mobileDots ? '.......................' : ''}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <img src={ten} />
                        </p>
                    </Box>
                    <br></br>
                    <br></br>
                    <Box className={classes.name}>
                        <p id="text">
                            Programas de educación financiera
                            {reallySmall ? '........' : ''}
                            {smallerDots ? '...............' : '...........'}
                            {mobileDots ? '.......................' : ''}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <img src={negative} />
                        </p>
                    </Box>

                    <Box className={classes.name}>
                        <p id="text">
                            Totales del sector
                            {reallySmall ? '........' : ''}
                            {smallerDots ? '..............................................' : '...........'}
                            {mobileDots ? '.......................' : ''}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <img src={eight} />
                        </p>
                    </Box>
                </Box>
            </Box>
            <Box marginTop={15} marginBottom={10}>
                <img src={line} />
            </Box>
            <Box>
                <p id="subtitle">Periodo: Enero — Diciembre 2021</p>
                <p className={classes.header}>INSTITUCIÓN</p>
                <br></br>
                <p className={classes.name}>
                    Cuenca Tecnología, S.A. de C.V., Institución de Fondos de Pago Electrónico
                </p>
                <br></br>
                <Box className={classes.name}>
                    <p id="text">Cumplimiento a los Registros de Condusef</p>
                    <Box className={classes.name} marginLeft={'25px'}>
                        <p id="institution">SIPRES</p>
                        <p id="description">
                            Actualizó / Registró Validó Información
                            {reallySmall ? '.....' : ''}
                            {smallerDots ? '.....................' : '...........'}
                            {mobileDots ? '.............................' : ''}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <img src={positive} />
                        </p>
                    </Box>
                    <br></br>
                    <Box className={classes.name} marginLeft={'25px'}>
                        <p id="institution">IFIT</p>
                        <p id="description">
                            Actualizó / Registró Validó Información
                            {reallySmall ? '.....' : ''}
                            {smallerDots ? '.....................' : '...........'}
                            {mobileDots ? '.............................' : ''}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <img src={positive} />
                        </p>
                    </Box>
                    <br></br>
                    <Box className={classes.name} marginLeft={'25px'}>
                        <p id="institution">
                            Calificación 0-10
                            {reallySmall ? '........' : ''}
                            {smallerDots ? '......................................' : '...........'}
                            {mobileDots ? '.......................' : ''}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <img src={ten} />
                        </p>
                    </Box>
                    <br></br>
                    <br></br>
                    <Box className={classes.name}>
                        <p id="text">
                            Programas de educación financiera
                            {reallySmall ? '........' : ''}
                            {smallerDots ? '...............' : '...........'}
                            {mobileDots ? '.......................' : ''}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <img src={negative} />
                        </p>
                    </Box>

                    <Box className={classes.name}>
                        <p id="text">
                            Totales del sector
                            {reallySmall ? '........' : ''}
                            {smallerDots ? '..............................................' : '...........'}
                            {mobileDots ? '.......................' : ''}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <img src={nine} />
                        </p>
                    </Box>
                </Box>
            </Box>
            <Box marginTop={30} className={classes.logoBuro}>
                <img src={logoBuro} alt="Buró" />
            </Box>
        </Box>
    );
}

export default withStyles(styles)(Buro);

import { Theme, createStyles } from '@material-ui/core/styles';

// gif
import gridGif from 'images/gifs/grid-home.gif';

export const styles = (theme: Theme) =>
    createStyles({
        root: {
            fontFamily: 'MuseoSans, sans-serif',
            color: theme.palette.primary.main,
            marginTop: '237px',
            marginLeft: '25%',
            marginBottom: '237px',
            width: '58%',
            '& #first': {
                marginBottom: '105px',
                fontWeight: 500,
                fontSize: '21.24px',
                letterSpacing: '1.5px',
                textAlign: 'left',
                color: '#3D4274',
                lineHeight: '51.55px',
            },
            '& #second': {
                width: '99%',
                marginTop: '35px',
                fontSize: '18.33px',
                letterSpacing: '0.75px',
                lineSpacing: '5px',
                color: '#3D4274',
                lineHeight: '26.35px',
            },
            '& #subtitle': {
                marginBottom: '50px',
                fontWeight: 500,
                fontSize: '32.24px',
                letterSpacing: '1.5px',
                textAlign: 'left',
                color: '#3D4274',
                lineHeight: '51.55px',
            },
            '& span': {
                color: '#000',
                fontStyle: 'italic',
            },
            [theme.breakpoints.up(1441)]: {
                maxWidth: '835px',
                margin: '237px auto 237px 35%',
            },
            [theme.breakpoints.down(1200)]: {
                width: '70.83%',
            },
            [theme.breakpoints.down(769)]: {
                marginLeft: '22.13%',
                marginBottom: '200px',
                '& #first': {
                    fontSize: '26px',
                    letterSpacing: '1.26px',
                    lineHeight: '51px',
                    marginBottom: '85px',
                },
                '& #second': {
                    fontSize: '21px',
                    letterSpacing: '0.75px',
                    lineHeight: '30px',
                },
                '& #subtitle': {
                    fontSize: '26px',
                    letterSpacing: '1.26px',
                    lineHeight: '51px',
                    marginBottom: '50px',
                },
            },
            [theme.breakpoints.down(701)]: {
                '& #first': {
                    fontSize: '20px',
                    letterSpacing: '1.31px',
                    lineHeight: '45px',
                    marginBottom: '75px',
                },
                '& #second': {
                    fontSize: '19px',
                    letterSpacing: '0.34px',
                    lineHeight: '28px',
                },
                '& #subtitle': {
                    fontSize: '20px',
                    letterSpacing: '1.31px',
                    lineHeight: '45px',
                    marginBottom: '50px',
                },
            },
            [theme.breakpoints.down(650)]: {
                marginTop: '150px',
                marginBottom: '175px',
                '& #first': {
                    fontSize: '15px',
                    letterSpacing: '1px',
                    lineHeight: '40px',
                    marginBottom: '65px',
                },
                '& #second': {
                    fontSize: '16px',
                    letterSpacing: '0.5px',
                    lineHeight: '26px',
                },
                '& #subtitle': {
                    fontSize: '15px',
                    letterSpacing: '1px',
                    lineHeight: '40px',
                    marginBottom: '50px',
                },
            },
            [theme.breakpoints.down(501)]: {
                marginLeft: '21.85%',
                marginTop: '125px',
                marginBottom: '150px',
                '& #first': {
                    fontSize: '10.14px',
                    letterSpacing: '0.75px',
                    lineHeight: '25.71px',
                    marginBottom: '50px',
                },
                '& #second': {
                    marginTop: '23px',
                    fontSize: '10.86px',
                    letterSpacing: '0.45px',
                    lineHeight: '16px',
                },
                '& #subtitle': {
                    fontSize: '10.14px',
                    letterSpacing: '0.75px',
                    lineHeight: '25.71px',
                    marginBottom: '50px',
                },
            },
        },
        linkInsideP: {
            color: theme.palette.primary.main,
            fontStyle: 'italic',
            fontWeight: 700,
            textDecoration: 'none',
        },
        logoBuro: {
            marginTop: '120px',
            [theme.breakpoints.down(650)]: {
                '& img': {
                    width: '35%',
                },
            },
            [theme.breakpoints.down(501)]: {
                '& img': {
                    width: '25%',
                },
            },
        },
        title: {
            fontWeight: 900,
            fontSize: '59.57px',
            letterSpacing: '7px',
            marginBottom: '0',
            [theme.breakpoints.down(769)]: {
                letterSpacing: '9.5px',
                fontSize: '51px',
            },
            [theme.breakpoints.down(701)]: {
                fontSize: '50px',
                letterSpacing: '6.92px',
            },
            [theme.breakpoints.down(650)]: {
                fontSize: '45px',
                letterSpacing: '4px',
            },
            [theme.breakpoints.down(501)]: {
                fontSize: '29.85px',
                letterSpacing: '3.95px',
            },
        },
        header: {
            fontWeight: 900,
            fontSize: '18px',
            letterSpacing: '2px',
            marginBottom: '0',
            [theme.breakpoints.down(769)]: {
                letterSpacing: '9.5px',
                fontSize: '18px',
            },
            [theme.breakpoints.down(701)]: {
                fontSize: '15px',
                letterSpacing: '2px',
            },
            [theme.breakpoints.down(650)]: {
                fontSize: '12px',
                letterSpacing: '2px',
            },
            [theme.breakpoints.down(501)]: {
                fontSize: '10px',
                letterSpacing: '2px',
            },
        },
        name: {
            fontWeight: 600,
            fontSize: '18px',
            letterSpacing: '0.55px',
            marginBottom: '0',
            '& #text': {
                fontWeight: 400,
                fontSize: '18.4px',
            },
            '& #institution': {
                fontWeight: 400,
                marginBottom: '-10px',
                fontSize: '19px',
            },
            '& #description': {
                fontWeight: 400,
                marginBottom: '0px',
                fontSize: '15px',
            },
            [theme.breakpoints.down(769)]: {
                letterSpacing: '0.5px',
                fontSize: '14px',
            },
            [theme.breakpoints.down(701)]: {
                fontSize: '13px',
                letterSpacing: '0.5px',
            },
            [theme.breakpoints.down(650)]: {
                fontSize: '10px',
                letterSpacing: '0.4px',
            },
            [theme.breakpoints.down(501)]: {
                fontSize: '8px',
                letterSpacing: '0.4px',
            },
        },
    });
